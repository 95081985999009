import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { employesUnderline } from 'images/koppelingen'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { dyflexis, dyflexisScreenshot } from 'images/koppelingen'
import Video from "components/video"

const dummyKlantVerhaal = {
  pageTitle: '“Makkelijk contact en duidelijkheid voor iedereen”',
  pageStreamer: 'Marieke de Vos, Codaisseur',
  useCase: 'Founders',
  person: {
    name: 'Marieke',
    functionTitle: 'HR MANAGER, ebay',
    companyLogo: 'https://via.placeholder.com/129x23',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224/ff48df',
  },
  content: [{
    title: 'Over Dyflexis',
    description: 'Passie voor je vak is het belangrijkste dat er is. Echter staat de administratieve rompslomp vaak in de weg. Met Dyflexis zorg je voor focus op taken die er écht toe doen. Met de gebruiksvriendelijke Workforce Management software van Dyflexis zorg jij ervoor dat de juiste persoon, met de juiste vaardigheden, altijd op de juiste plek staat. Door een optimale personeelsbezetting en accurate urenregistratie heb jij grip op de organisatie, zorg je voor rust in de tent en houd je tijd over om het beste uit het personeel te halen. We plan to achieve.'
    }, {
      title: 'De werking van de koppeling met Dyflexis',
      description: 'De koppeling met Employes zorgt ervoor dat je gemakkelijk gewerkte uren kunt importeren in een verloning in Employes. Daarnaast worden de werknemer- en contractgegevens gesynchroniseerd. Verder is het mogelijk om vrije velden te synchroniseren naar Dyflexis.',
    },{
      title: 'Functies van de koppeling',
      list: ['Uren importeren','Werknemers exporteren','Synchroniseren contracten']
    }, {
      image: dyflexisScreenshot
    }, {
      title: 'Tarieven van de koppeling',
      description: 'Het gebruik van de koppeling is geheel gratis. Wel zul je voor het gebruik van de koppeling een API-token moeten aanvragen bij Dyflexis. Hier zijn mogelijk kosten aan verbonden.'
  }]
}

class Dyflexis extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling Dyflexis & Employes | Salarisadministratie</title>
            <meta name="description" content="Automatisch gewerkte uren importeren in je Employes salarisadministratie met de koppeling van Dyflexis."/>
            <meta itemprop="name" content="Koppeling Dyflexis & Employes | Salarisadministratie" />
            <meta itemprop="description" content="Automatisch gewerkte uren importeren in je Employes salarisadministratie met de koppeling van Dyflexis."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="koppeling dyflexis padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel Dyflexis aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employesUnderline} alt="employes logo"/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={dyflexis} alt="Dyflexis logo"/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const isVideo = (firstObjectKey === 'video')
                  const { title, description, list } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isVideo ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isVideo ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                {description && (<p>{description}</p>)}
                                {list && list.length && (<ul>{list.map(point => (<li>{point}</li>))}</ul>)}
                              </div>
                            ),
                            image: (
                              <img src={section.image} alt="section" />
                            ),
                            video: (
                                <Video videoSrcURL={section.video} videoTitle="Koppeling Moneybird en Employes salarisadministratie"/>
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <a href="https://www.dyflexis.com/" target="_blank" rel="nofollow">Lees meer over Dyflexis</a>
                </div>
              </div>
            </div>
          </div>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Dyflexis.propTypes = {
  data: PropTypes.object
}

export default Dyflexis
